import React from "react";
import Tree from "../../assets/building.webp";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Amphora from '../../assets/amphora.webp'
import David from '../../assets/david.webp'
import Helmet from "../../assets/helmet.webp"


function DagoraInfo() {




  return (
    <div className="md:flex flex-col items-center justify-center min-h-screen md:px-72 p-4 pt-12 bg-gradient-to-b from-DAgora-Blue to-DAgora-Main">
      <h2 className="items-center pb-10 md:pb-32 text-white font-megrim font-bold text-center text-4xl md:text-8xl">
        What is dAgora
      </h2>
      <div>
        <Carousel 
          autoPlay
          infiniteLoop 
          showArrows={false}
          showStatus={false}
          showThumbs={false} 
          className="py-10 drop-shadow-2xl shadow-2xl shadow-DAgora-Black rounded-md"
        >
          <div className="flex items-center justify-evenly rounded-md py-4 md:py-24 md:h-[600px]">
            <p className="w-64 md:w-3/6 md:text-3xl text-lg font-Poiret-One font-bold pl-4">
              Technology is our primary area of emphasis. However, this doesn't
              work for everyone. We aspire to fill many categories as we expand
              this concept. But remember this, technology is human— we have our
              hands in the processes.
            </p>
            <div>
              <img src={Helmet} alt="img" className="object-scale-down"/>
            </div>
          </div>
          <div className="flex items-center justify-evenly  rounded-md py-4 md:py-24 md:h-[600px]">
            <div>
              <img src={David} alt="img" className="hidden object-scale-down"/>
            </div>
            <p className="w-64 md:w-3/6 md:text-3xl text-lg font-Poiret-One font-bold pr-4">
              DecentrAgora— or dAgora for short, is a new business concept with
              origins going back to antiquity. As an organization, we strive to
              appease the public. We are all part of the bigger picture. As a
              space, dAgora grows over time into a network of areas similar to a
              library. The key difference is our focus.
            </p>
          </div>
          <div className="flex items-center justify-evenly rounded-md py-4 md:py-24 md:h-[600px]">
            <p className="w-64 md:w-3/6 md:text-3xl text-lg font-Poiret-One font-bold pl-4">
              At an advanced level, the Strategoi— the highest-ranking members of
              DecentrAgora, coordinate with open ecosystems like Ethereum. We are
              openly transparent, with motivation and determination directed
              towards engaging and teaching the public about technology,
              under-taught subjects and general opportunities/innovations outside
              regular fields.
            </p>
            <div>
              <img src={Amphora} alt="img" className="object-scale-down"/>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default DagoraInfo;
