import React from 'react'
import Scroll from '../../assets/DagoraContact.webp'


function ContactUs() {
    return(
        <div className='md:flex justify-center px-10 md:px-96 items-center min-h-screen bg-gradient-to-b py-10 from-DAgora-Blue to-DAgora-Main'>
            <div className=" md:px-10">   
                <h2 className="text-center text-2xl md:text-4xl font-semibold font-Poiret-One py-8">Contact Us</h2>
                <h3 className="text-xl md:text-2xl font-Poiret-One font-semibold py-4">Email:</h3>
                <a href={"mailto:" + "logistics@decentragora.xyz"} className="font-middle md:text-xl font-Economica py-1">logistics@decentragora.xyz</a>
                <h3 className="text-xl md:text-2xl font-Poiret-One font-semibold py-4">Any Suggestions?</h3>
                <a target='blank' href='https://t.co/SxtK09KZho'><button className='bg-DAgora-Blue-Lite p-4  rounded-xl font-bold md:text-base text-white shadow-xl'>Google Form</button></a>
                {/* <a href='https://t.co/SxtK09KZho' className="font-middle md:text-xl font-Economica py-1">Please fill our google form</a> */}
            </div>
                <img 
                    src={Scroll} 
                    className="max-h-[800px] w-[800px] "
                />
        </div>
    )
}

export default ContactUs