import react from 'react'
import Decree from '../../assets/decree.webp'

function Join() {
    return(
        <div className='md:flex justify-center md:px-32 items-center min-h-screen md:py-48 bg-DAgora-Main '>
        <div className="md:grid md:grid-cols-1 md:grid-rows-5 py-8">
            <h2 className="text-center text-4xl md:text-6xl font-Poiret-One font-medium">Join our Network!</h2>
            <p className="text-lg md:text-2xl py-6 px-6"> We are open from 9am to 5pm, Monday through Friday</p>
            <p className="text-lg md:text-2xl py-6 px-6">Drop in classes will be available every second Sunday</p>
            <p className="text-lg md:text-2xl py-6 px-6">DecentrAgora is closed to the public on Sundays</p>
        </div>
        <div className="rounded-xl shadow-xl mx-5">
        <img 
            src={Decree}
            width="600"
            height="700"
        />
        </div>
    </div>
    )
}

export default Join